<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '480px' }"
    header="Fomulário de CBO"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field">
      <label for="codigo">Código</label>
      <InputText
        id="codigo"
        v-model="v$.cbo.codigo.$model"
        maxlength="100"
        placeholder="Digite código do CBO"
        :class="{ 'p-invalid': submitted && v$.cbo.codigo.$invalid }"
      />
      <small class="p-error" v-if="submitted && v$.cbo.codigo.$invalid"
        >Código é obrigatório.</small
      >
    </div>
    <div class="field">
      <label for="descricao">Descrição</label>
      <InputText
        id="descricao"
        v-model="v$.cbo.descricao.$model"
        maxlength="100"
        placeholder="Digite a descrição"
        :class="{ 'p-invalid': submitted && v$.cbo.descricao.$invalid }"
      />
      <small class="p-error" v-if="submitted && v$.cbo.descricao.$invalid"
        >Descrição é obrigatório.</small
      >
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.cbo.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
  <script>
//Models
import Cbo from "../../../models/cbo";

//Services
import CboService from "../../../service/cbo/cbo_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["cboSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      cbo: new Cbo(),
      submitted: false,
      cboService: new CboService(),
    };
  },
  created() {},
  validations() {
    return {
      cbo: new Cbo().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.cbo.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.cbo.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.cbo.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.cboService
        .create(this.cbo)
        .then((data) => {
          if (data.status === 201) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.cboService
        .update(this.cbo)
        .then((data) => {
          if (data.status === 200) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.cbo = new Cbo();
      this.submitted = false;
      this.$emit("findAll");
      this.visibleDialog = false;
    },
    getData() {
      this.cbo = this.cboSelected;
    },
  },
};
</script>
  <style scoped></style>
  